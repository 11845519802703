import { Button, Descriptions, Table, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import Moment from 'moment';
import 'moment/locale/tr'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getSessions } from '../../../../core/apis/Apis';
import { setError, setLoading, setSuccess } from '../../../../core/stores/apiStatusControllerSlice';
import './Sessions.scss';
import gen2Devices from '../../../../../src/gen2_devices.json';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SEO_HELMET } from '../../../../core/utils/helmet';
import { setTitle } from '../../../../core/stores/navbarTitleControllerSlice';
import { useTranslation } from 'react-i18next';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';


function Sessions() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const {t} = useTranslation();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [record] = useState({
        gtwID: window.location.pathname.split('GTWY-')[1].split('/')[0],
    })
    const [filteredInfo, setFilteredInfo] = useState({});
    const [responseData, setResponseData] = useState();
    const [responseDataLoading, setResponseDataLoading] = useState(false);
    const [dateString, setDateString] = useState([]);
    const [logType, setLogType] = useState(null)
    const [tableColumns, setTableColumns] = useState([])
    const [filterCounter, setFilterCounter] = useState(0)
    const [sessionsStatus, setSessionsStatus] = useState([])
    const [sessionsMessage, setSessionsMessage] = useState([])
    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('pages.sessions.title')))
        // SetLocalStorageApi()
        setResponseDataLoading(true)
        getData()

        return () => {
            setResponseDataLoading(false)
            setResponseData(null)
            setFilterCounter(0)
        }
    }, [])

    const devices = gen2Devices['devices'].map(deviceID => deviceID.toString().split('-')[1])

    const onClickFilterButton = () => {
        setResponseDataLoading(true)
        getDataFilterDate()
    }

    const getData = () => {

        getSessions('GTWY-' + record.gtwID)
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data)
                }
                else {
                    toast.error(`${res.data.error}`, {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const getDataFilterDate = () => {
        const params = {
            start: dateString[0],
            end: dateString[1],
            log_type: logType
        }

        getSessions('GTWY-' + record.gtwID, params)
            .then(res => {
                if (res.status === 200) {
                    console.log("res : ", res)
                    setResponseData(res.data)
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))

                setFilterCounter(prev => prev + 1)

            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })

    }

    /* -------------------------------------------------------------------------- */
    //TODO                          FOR TABLE PROPS                               */
    /* -------------------------------------------------------------------------- */

    const tablePropOnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };
    const columns = [
        {
            title: t('pages.sessions.date'),
            dataIndex: 'date',
            key: 'date',
            width: 140,
            fixed: 'left',
        },
        {
            title: t('pages.sessions.status'),
            dataIndex: 'status',
            key: 'status',
            filteredValue: filteredInfo.status || null,
            filters: sessionsStatus.length > 0 && sessionsStatus.map(res => {
                return {
                    text: res.toUpperCase(),
                    value: res,
                }
            }),
            onFilter: (value, record) => record.status === value,
            render: (status) => <div style={{ textAlign: 'center', }} >{status}</div>,
        },
        {
            title: t('pages.sessions.message'),
            dataIndex: 'message',
            key: 'message',
            filteredValue: filteredInfo.message || null,
            filters: sessionsMessage.length > 0 && sessionsMessage.map(res => {
                return {
                    text: res.toUpperCase(),
                    value: res,
                }
            }),
            onFilter: (value, record) => record.message === value,
            render: (message) => <div style={{ textAlign: 'center', }} >{message}</div>,
        },
    ];

    const dataSource = useMemo(() => {
        var list = []
        var sessionStatusList = []
        var sessionMessageList = []
        if (responseData) {
            responseData.logs.map((res, index) => {
                if (res.status) {
                    sessionStatusList.push(res.status)
                }
                if (res.message) {
                    sessionMessageList.push(res.message)
                }

                if (devices.includes(record.gtwID)) {

                    list.push({
                        key: index,
                        date: Moment.unix(res.timestamp).format("DD/MM/YY, HH:mm:ss"),
                        ...res
                    })
                } else {
                    list.push({
                        key: index,
                        date: Moment.unix(res.timestamp).format("DD/MM/YY, HH:mm:ss"),
                        status: res.status,
                        message: res.message,
                    })

                    setSessionsStatus(Array.from(new Set(sessionStatusList)))
                    setSessionsMessage(Array.from(new Set(sessionMessageList)))
                }
            })
        }
        console.log("list : ", list)

        return list
    }, [responseData])

    useEffect(() => {
        const generalTableColumns = [
            {
                title: t('pages.sessions.date'),
                dataIndex: 'date',
                key: 'date',
                width: 140,
                fixed: 'left',
            }
        ]

        if (devices.includes(record.gtwID)) {
            if (logType === '0') {
                setTableColumns(
                    generalTableColumns.concat([
                        {
                            title: t('pages.sessions.message'),
                            dataIndex: 'message',
                            key: 'message'
                        },
                        {
                            title: t('pages.sessions.info'),
                            dataIndex: 'info',
                            key: 'info'
                        }
                    ])
                )
            } else if (logType === '1') {
                setTableColumns(
                    generalTableColumns.concat([
                        {
                            title: t('pages.gateway_detail.rooms_and_sensors_table.room_id'),
                            dataIndex: 'room_id',
                            key: 'room_id'
                        },
                        {
                            title: t('pages.sessions.room_type'),
                            dataIndex: 'room_type',
                            key: 'room_type'
                        },
                        {
                            title: t('pages.sessions.person_status'),
                            dataIndex: 'person_status',
                            key: 'person_status',
                            render: text => text && t(`pages.sessions.person_status_${text}`)

                        },
                        {
                            title: t('pages.sessions.total_motion_trigger'),
                            dataIndex: 'total_motion_trigger',
                            key: 'total_motion_trigger'
                        },
                        {
                            dataIndex: 'gmt_offset',
                            key: 'gmt_offset',
                            title: t('pages.sessions.gmt_offset')
                        }
                    ])
                )
            } else if (logType === '2') {
                setTableColumns(
                    generalTableColumns.concat([
                        {
                            title: t('pages.sessions.info'),
                            dataIndex: 'info',
                            key: 'info'
                        },
                        {
                            title: t('pages.sessions.duration'),
                            dataIndex: 'duration',
                            key: 'duration'
                        },
                        {
                            title: t('pages.gateway_detail.description_items.last_room_id'),
                            dataIndex: 'last_room_id',
                            key: 'last_room_id'
                        }
                    ])
                )
            } else if (logType === '3') {
                setTableColumns(
                    generalTableColumns.concat([
                        {
                            title: t('pages.sessions.notification_type'),
                            dataIndex: 'notification_type',
                            key: 'notification_type',
                            render: text => text && t(`pages.sessions.notification_type_${text}`)
                        },
                        {
                            title: t('pages.sessions.last_room_id'),
                            dataIndex: 'last_room_id',
                            key: 'last_room_id'
                        },
                        {
                            title: t('pages.sessions.last_seen_timestamp'),
                            dataIndex: 'last_seen_timestamp',
                            key: 'last_seen_timestamp',
                            render: text => text && Moment.unix(text).format("DD/MM/YY, HH:mm:ss")
                        }
                    ])
                )
            } else {
                setTableColumns(
                    generalTableColumns.concat([
                        {
                            title: t('pages.sessions.message'),
                            dataIndex: 'message',
                            key: 'message'
                        },
                        {
                            title: t('pages.sessions.info'),
                            dataIndex: 'info',
                            key: 'info'
                        },
                        {
                            title: t('pages.gateway_detail.rooms_and_sensors_table.room_id'),
                            dataIndex: 'room_id',
                            key: 'room_id'
                        },
                        {
                            title: t('pages.sessions.room_type'),
                            dataIndex: 'room_type',
                            key: 'room_type'
                        },
                        {
                            title: t('pages.sessions.person_status'),
                            dataIndex: 'person_status',
                            key: 'person_status',
                            render: text => text && t(`pages.sessions.person_status_${text}`)
                        },
                        {
                            title: t('pages.sessions.total_motion_trigger'),
                            dataIndex: 'total_motion_trigger',
                            key: 'total_motion_trigger'
                        },
                        {
                            title: t('pages.sessions.duration'),
                            dataIndex: 'duration',
                            key: 'duration'
                        },
                        {
                            title: t('pages.gateway_detail.description_items.last_room_id'),
                            dataIndex: 'last_room_id',
                            key: 'last_room_id'
                        },
                        {
                            title: t('pages.sessions.notification_type'),
                            dataIndex: 'notification_type',
                            key: 'notification_type',
                            render: text => text && t(`pages.sessions.notification_type_${text}`)
                        },
                        {
                            title: t('pages.sessions.last_seen_timestamp'),
                            dataIndex: 'last_seen_timestamp',
                            key: 'last_seen_timestamp',
                            render: text => text && Moment.unix(text).format("DD/MM/YY, HH:mm:ss")
                        },
                        {
                            dataIndex: 'gmt_offset',
                            key: 'gmt_offset',
                            title: t('pages.sessions.gmt_offset')
                        }
                    ])
                )
            }
        } else {
            setTableColumns(columns)
        }

        return () => {
            setTableColumns([])
        }
    }, [filterCounter])

    var onChangeRangePicker = (date, dateString) => {
        setDateString(dateString)
    }
    return (
        <>
            <SEO_HELMET title={`${t('pages.sessions.title')} / ${record.gtwID}`} />
            <div id="Sessions">
                <div className="Sessions-cover">
                    {/* <Divider orientation='left'>{record.gtwID} - Sessions</Divider> */}
                    <Descriptions size='small' bordered column={{
                        xxl: 4,
                        xl: 4,
                        lg: 4,
                        md: 3,
                        sm: 2,
                        xs: 1
                    }}>
                        <Descriptions.Item label={t('description_items.gateway_id')} labelStyle={{ width: '100px' }} contentStyle={{ width: '160px' }} >
                            {record.gtwID}
                        </Descriptions.Item>

                        {devices.includes(record.gtwID) &&
                        <Descriptions.Item label={t('pages.sessions.log_type')} labelStyle={{ width: '100px' }} contentStyle={{ width: '200px' }} >
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={t('pages.sessions.log_type')}
                                onChange={(value) => setLogType(value)}
                            >
                                <Select.Option value={null} key={"all"}>{t('pages.sessions.all_logs')}</Select.Option>
                                <Select.Option value="0" key="0">{t('pages.sessions.system_logs')}</Select.Option>
                                <Select.Option value="1" key="1">{t('pages.sessions.person_status_logs')}</Select.Option>
                                <Select.Option value="2" key="2">{t('pages.sessions.session_analysis')}</Select.Option>
                                <Select.Option value="3" key="3">{t('pages.sessions.notification_logs')}</Select.Option>
                            </Select>
                        </Descriptions.Item>
                        }
                        <Descriptions.Item label={t('description_items.date')} labelStyle={{ width: '100px' }} contentStyle={{ width: '300px' }} >
                            <RangePicker
                                format={dateFormat}
                                onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: 'max-content' }}><Button disabled={!dateString && true} onClick={dateString ? () => onClickFilterButton() : null}>{t('description_items.filter')}</Button></Descriptions.Item>
                    </Descriptions>
                    <div className="Sessions-bottom_more_info mt-2">
                        <Table
                            footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>

                                <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button>
                            </div>
                            }
                            size='middle'
                            bordered={true}
                            columns={tableColumns}
                            pagination={false}
                            dataSource={dataSource}
                            loading={responseDataLoading}
                            scroll={{
                                y: 'calc(100vh - 320px)',
                            }}
                            onChange={tablePropOnChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sessions
