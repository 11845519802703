import { Button, Descriptions,InputNumber, Collapse, Row, Col, Space, Tooltip, theme } from 'antd';
import React, { useState } from 'react'
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { getConfigsApi, getDefaultParams, updateConfigsApi } from '../../../../core/apis/Apis';
import { setError, setLoading, setSuccess } from '../../../../core/stores/apiStatusControllerSlice';
import { useEffect } from 'react';
import './UpdateConfigs.scss'
import { SEO_HELMET } from '../../../../core/utils/helmet';
import { setTitle } from '../../../../core/stores/navbarTitleControllerSlice';
import PageLoading from '../../../../components/loadings/PageLoading';
import { useTranslation } from 'react-i18next';
const { Panel } = Collapse;

function UpdateConfigs() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    // const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    // const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const {
        authReducers: { user_info: authUser },
    } = useSelector((state) => state);
    const {t} = useTranslation();
    const {
        token: {
            colorTextHeading,
            colorTextLabel,
            colorBorder
        }
    } = theme.useToken();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [record] = useState({
        gtwID: window.location.pathname.split('GTWY-')[1].split('/')[0],
    })
    const [responseData, setResponseData] = useState();
    // const [responseDataCfgList, setResponseDataCfglist] = useState([]);
    const [responseDataLoading, setResponseDataLoading] = useState(true);
    const [defaultParams, setDefaultParams] = useState();

    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('pages.update_configs.title')))
        // SetLocalStorageApi()
        getData()
    }, [])
    const getData = () => {
        getConfigsApi("GTWY-" + record.gtwID)
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data)
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })

        getDefaultParams()
            .then(res => {
                if (res.status === 200) {
                    setDefaultParams(res.data)
                }
            })
            .catch(err => {
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

            })            
    }


    /* -------------------------------------------------------------------------- */
    //TODO                      FOR SENSORS TABLE PROPS                           */
    /* -------------------------------------------------------------------------- */

    const [updadeButtonClicked, setUpdadeButtonClicked] = useState(false)
    const gtwSensorsSave = async () => {
        setUpdadeButtonClicked(true)

        const updateProps = {
            "node_configs": responseData.node_configs,
            "gateway_configs": responseData.gateway_configs
        }

        await updateConfigsApi(updateProps, "GTWY-" + record.gtwID)
            .then(res => {
                if (res.status === 200) {
                    // dispatch(setIsModalOpenAntd(false))
                    getData();
                    toast.success(t('pages.update_configs.params_updated_toast'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log("err:",err);
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
        setUpdadeButtonClicked(false)

    };

    const nodeParamLabels = [
        t('pages.update_configs.node_params.p1'),
        t('pages.update_configs.node_params.p2'),
        t('pages.update_configs.node_params.p3'),
        t('pages.update_configs.node_params.p4'),
        t('pages.update_configs.node_params.p5'),
        t('pages.update_configs.node_params.p6'),
        t('pages.update_configs.node_params.p7'),
        t('pages.update_configs.node_params.p8'),
        t('pages.update_configs.node_params.p9'),
        t('pages.update_configs.node_params.p10'),
        t('pages.update_configs.node_params.p11'),
        t('pages.update_configs.node_params.p12')
    ]

    const findSensorType = (res) => {

        let responseDataCfgList = {}
        
        switch (res.type) {
            case 1:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.motion'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": Object.values(res.cfg)[1],
                    "p3": Object.values(res.cfg)[2],
                    "p4": Object.values(res.cfg)[3],
                    "p5": Object.values(res.cfg)[4],
                    "p6": Object.values(res.cfg)[5],
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
            case 2:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.door'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": Object.values(res.cfg)[1],
                    "p3": Object.values(res.cfg)[2],
                    "p4": '-',
                    "p5": '-',
                    "p6": '-',
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
            case 3:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.seat'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": Object.values(res.cfg)[1],
                    "p3": Object.values(res.cfg)[2],
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p4": Object.values(res.cfg)[3],
                    "p5": Object.values(res.cfg)[4],
                    "p6": Object.values(res.cfg)[5],
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
            case 4:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.emergency_button'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": Object.values(res.cfg)[1],
                    "p3": Object.values(res.cfg)[2],
                    "p4": '-',
                    "p5": '-',
                    "p6": '-',
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
            case 5:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.bathroom'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": Object.values(res.cfg)[1],
                    "p3": Object.values(res.cfg)[2],
                    "p4": Object.values(res.cfg)[3],
                    "p5": Object.values(res.cfg)[4],
                    "p6": Object.values(res.cfg)[5],
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
            case 6:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.bed'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": Object.values(res.cfg)[1],
                    "p3": Object.values(res.cfg)[2],
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-',
                    "p4": Object.values(res.cfg)[3],
                    "p5": Object.values(res.cfg)[4],
                    "p6": Object.values(res.cfg)[5]
                }
                break;
            case 7:
                responseDataCfgList = {
                    "type": t('pages.update_configs.node_params.extender'),
                    "p1": Object.values(res.cfg)[0],
                    "p2": '-',
                    "p3": '-',
                    "p4": '-',
                    "p5": '-',
                    "p6": '-',
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
            default:
                responseDataCfgList = {
                    "type": t('general_unknown'),
                    "p1": '-',
                    "p2": '-',
                    "p3": '-',
                    "p4": '-',
                    "p5": '-',
                    "p6": '-',
                    "p7": '-',
                    "p8": '-',
                    "p9": '-',
                    "p10": '-',
                    "p11": '-',
                    "p12": '-'
                }
                break;
        }
        return responseDataCfgList
    }
    
    const readOnlyParams = [
        'p1'
    ]

    const updateGatewayParamValue = (key, newValue) => {
        setResponseData(prevData => ({
            ...prevData,
            gateway_configs: {
                ...prevData.gateway_configs,
                [key]: newValue
            }
        }))
    }

    const updateNodeParamValue = (nodeID, key, newValue) => {
        
        const nodeIndex = responseData.node_configs.findIndex(node => node.id === nodeID);

        setResponseData(prevData => ({
            ...prevData,
            node_configs: [
                ...prevData.node_configs.slice(0, nodeIndex),
                {
                    ...prevData.node_configs[nodeIndex],
                    cfg: {
                        ...prevData.node_configs[nodeIndex].cfg,
                        [key]: newValue
                    }
                },
                ...prevData.node_configs.slice(nodeIndex + 1)
            ]
        }))
    }

    const gatewayParmeters = useMemo(() => {
        if (responseData?.gateway_configs && defaultParams?.gateway) return (
            <Descriptions bordered column={2} size='small'>
                {Object.entries(responseData?.gateway_configs)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, { numeric: true }))
                    .map(([key, value], index) => {
                        const isReadOnly = readOnlyParams?.includes(key);
                        const isDefaultValue = defaultParams?.gateway[key] === value;
                        return (
                            <Descriptions.Item span={{ xs: 2, lg: 1 }} key={key} label={t(`pages.update_configs.gateway_params.${key}`)}>
                                <Row justify={'center'} align={'middle'} className='gap-4'>
                                    <InputNumber
                                        onChange={(e) => {
                                            updateGatewayParamValue(key, e);
                                        }}
                                        style={{ width: '50%' }}
                                        value={value}
                                        readOnly={isReadOnly}
                                        disabled={isReadOnly}
                                    />
                                    <Button
                                        type="primary"
                                        size='middle'
                                        onClick={() => {
                                            updateGatewayParamValue(key, defaultParams?.gateway[key]);
                                        }}
                                        disabled={isReadOnly || isDefaultValue}
                                    >
                                        {t('general.reset')}
                                    </Button>
                                </Row>
                            </Descriptions.Item>
                        );
                    })}
            </Descriptions>
        );
    }, [responseData?.gateway_configs, defaultParams])


    const nodeParameters = useMemo(() => {
        return (
            <table className="table table-hover table-borderless table-sm table-responsive custom-table">
                <thead className='text-center'>
                    <tr>
                        <th scope="col" className='align-middle' style={{color: colorTextHeading}}>
                            {t('pages.update_configs.node_params.node_id')}
                        </th>
                        {
                            nodeParamLabels.map((label, labelIndex) => {
                                return (
                                    <th key={labelIndex} scope="col" className='align-middle' style={{color: colorTextHeading}}>
                                        <div>
                                            <span>{label}</span>
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {responseData?.node_configs?.map(nodeConfig => {
                        const nodeConfigDetails = findSensorType(nodeConfig)
                        return (
                            <tr key={nodeConfig.id} className='text-center justify-content-center align-middle p-0'>
                                <th>
                                    <Row className='justify-content-center flex-column p-0' style={{color: colorTextHeading}}>
                                        <Col>{nodeConfig.id}</Col>
                                        <Col>{nodeConfigDetails['type']}</Col>
                                    </Row>
                                </th>
                                {nodeParamLabels.map((label, labelIndex) => {
                                    const nodeMapper = {
                                        1: 'MOTION',
                                        2: 'DOOR',
                                        3: 'FORCE',
                                        4: 'PANIC',
                                        5: 'BATH',
                                        6: 'BED',
                                        7: 'EXTENDER'
                                    }
                                    const isDisabled = nodeConfig.cfg[Object.keys(nodeConfigDetails)[labelIndex + 1]] === defaultParams?.node[nodeMapper[nodeConfig.type]][Object.keys(nodeConfigDetails)[labelIndex + 1]];
                                    return (
                                        <td>
                                        {Object.values(nodeConfigDetails)[labelIndex + 1] === '-' ?
                                            <div className='align-middle fw-semibold' style={{color: colorTextLabel}}>x</div>
                                            :
                                            
                                                <Row className='justify-content-center align-items-center text-center gap-2 flex-row'>
                                                    <Space.Compact
                                                        direction="horizontal"
                                                        size="small"
                                                        block
                                                        style={{ width: 'calc(100% - 20px)' }}
                                                    >
                                                        <InputNumber
                                                            onChange={(e) => {
                                                                updateNodeParamValue(nodeConfig.id, Object.keys(nodeConfigDetails)[labelIndex + 1], e)
                                                            }}
                                                            value={nodeConfig.cfg[Object.keys(nodeConfigDetails)[labelIndex + 1]]}
                                                        />
                                                        <Tooltip title={t('general.reset')}>
                                                            <Button
                                                                type="primary"
                                                                onClick={() => {
                                                                    updateNodeParamValue(
                                                                        nodeConfig.id,
                                                                        Object.keys(nodeConfigDetails)[labelIndex + 1],
                                                                        defaultParams?.node[nodeMapper[nodeConfig.type]][Object.keys(nodeConfigDetails)[labelIndex + 1]]
                                                                    )
                                                                }}
                                                                disabled={isDisabled}
                                                            >
                                                                <i class="fa-solid fa-arrow-rotate-left fa-xs"></i>
                                                            </Button>
                                                        </Tooltip>
                                                    </Space.Compact>
                                                </Row>
                                        }
                                        </td>
                                    )}
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }, [responseData?.node_configs, defaultParams])

    return (
        <>
            <SEO_HELMET title={`${t('pages.update_configs.title')} / ${record.gtwID}`} />
            <div id="UpdateConfigs" className='p-2 p-lg-3 mb-3 mb-lg-0'>
                <div className="UpdateConfigs-cover" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div className='mb-3' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <div style={{color: colorTextHeading}} className='fw-bold h6'>
                                {t('pages.update_configs.title')} - {record.gtwID}
                            </div>
                            {authUser?.is_superuser === true | authUser?.permissions?.gtw_detail_page_display_update_configs_update_button === true ?
                                <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button
                                        type="primary"
                                        htmlType='submit'
                                        onClick={() => gtwSensorsSave()}
                                    >
                                        {t('general.update')}
                                    </Button>
                                </div>
                                :
                                <div />
                            }
                        </div>
                        <div className="UpdateConfigs-bottom_more_info">
                            <Collapse defaultActiveKey={['1', '2']}>
                                <Panel header={t('pages.update_configs.gateway_parameters')} key="1">
                                    {/* updadeButtonClicked */}
                                    {updadeButtonClicked && <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: 'calc(100% - 50px)',
                                        bottom: '0px',
                                        right: '0px',
                                        padding: '10px',
                                        zIndex: '9999',
                                        background: 'transparent',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backdropFilter: 'blur(2px)',
                                    }}>
                                        <PageLoading />
                                    </div>
                                    }
                                    {responseDataLoading ?
                                        <PageLoading />
                                        :
                                        <>

                                            {responseData && responseData.gateway_configs &&
                                                <div>
                                                    {gatewayParmeters}
                                                </div>
                                            }

                                        </>
                                    }
                                </Panel>
                                <Panel header={t('pages.update_configs.sensor_parameters')} key="2" className='custom-panel' children={
                                    <div>
                                        {updadeButtonClicked &&
                                        <div
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: 'calc(100% - 50px)',
                                                bottom: '0px',
                                                right: '0px',
                                                padding: '10px',
                                                zIndex: '9999',
                                                background: 'transparent',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backdropFilter: 'blur(2px)',
                                            }}
                                        >
                                            <PageLoading />
                                        </div>
                                        }
                                        {responseDataLoading ?
                                            <PageLoading />
                                            :
                                            <div className='w-100'>
                                                {responseData?.node_configs && defaultParams?.node &&
                                                    nodeParameters
                                                }
                                            </div>
                                        }
                                    </div>
                                }

                                />
                            </Collapse>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default UpdateConfigs
